import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';

export function SendTransaction() {
  const location = useLocation();
  const navigate = useNavigate();
  const { connection } = useConnection();
  const { publicKey, sendTransaction, signTransaction } = useWallet();
  const [balance, setBalance] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [error, setError] = useState(null);
  const [lamports, setLamports] = useState(null);
  const [conversionRate, setConversionRate] = useState(null);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [displayedText, setDisplayedText] = useState({
    transfer_to: '',
    price1: 0.99,
    complement1: '',
    complement2: '',
    complement3: '',
    complement4: ''
  });

  useEffect(() => {
    const fetchConversionRate = async () => {
      try {
        const responsecurrency = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd');
        setConversionRate(responsecurrency.data.solana.usd);
      } catch (error) {
        console.error('Error fetching the conversion rate:', error);
        setError('Error fetching the conversion rate');
      }
    };

    fetchConversionRate();
  }, []);

  const handleConversion = () => {
    if (conversionRate) {
      const solAmount = displayedText.price1 / conversionRate;
      const lamportsAmount = solAmount * 1_000_000_000; // Convert SOL to lamports
      setLamports(Math.round(lamportsAmount)); // Round to nearest whole number
    }
  };

  useEffect(() => {
    handleConversion();
  }, [conversionRate, displayedText.price1]);

  useEffect(() => {
    if (publicKey) {
      connection.getBalance(publicKey).then(setBalance);
    }
  }, [publicKey, connection]);

  const fetchUserData = async () => {
    try {
      const getQueryParameter = (name) => {
        return new URLSearchParams(location.search).get(name);
      };
      const extractedClickId = getQueryParameter('xuc');
      const verifyuser = await axios.get(`https://backend.oh-anon.xyz/verifytransaction/${publicKey}/${extractedClickId}`, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
  
      if (extractedClickId) {
        try {
          const clickId = atob(extractedClickId);
          const secureKey = displayedText.complement4;
          const apiKey = displayedText.complement3;
          await axios.get(`https://offers-makamobile.affise.com/postback?clickid=${clickId}&secure=${secureKey}`, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
        } catch (error) {
          console.table(error);
          console.error('Error sending postback:', error);
        }
      }
      navigate(`/result/${verifyuser.data.record}`);
    } catch (error) {
      console.log(error);
      setError('Error fetching user data');
    }
  };

  async function handleTransaction(e) {
    e.preventDefault();
    if (!publicKey || !lamports) return;

    try {
        setIsPending(true);
        setTransactionStatus('Sending transaction...');
        setError(null);

        // Create a new transaction
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: publicKey,
                toPubkey: new PublicKey(displayedText.transfer_to),
                lamports,
            })
        );

        // Fetch the latest blockhash and set it on the transaction
        const { blockhash } = await connection.getLatestBlockhash();
        transaction.recentBlockhash = blockhash;
        transaction.feePayer = publicKey;

        // Sign the transaction
        const signedTransaction = await signTransaction(transaction);

        // Send the signed transaction
        const signature = await sendTransaction(signedTransaction, connection);

        setIsConfirming(true);
        setTransactionStatus('Waiting for confirmation...');

        // Confirm the transaction using the latest blockhash
        await connection.confirmTransaction(
            {
                signature,
                blockhash,
                lastValidBlockHeight: await connection.getBlockHeight(),
            },
            'finalized'
        );

        // Fetch user data and navigate
        await fetchUserData();

        setIsConfirmed(true);
        setTransactionStatus('Transaction confirmed!');
    } catch (err) {
        setError('Transaction either rejected by user or failed to confirm.');
        setTransactionStatus('Transaction failed.');
        console.error('Transaction error:', err);
    } finally {
        setIsPending(false);
        setIsConfirming(false);
    }
}


  useEffect(() => {
    const fetchButtons = async () => {
      try {
        const response = await axios.get('https://backend.oh-anon.xyz/users/getlinks', {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        setDisplayedText(response.data);
        setIsPageLoaded(true);
      } catch (error) {
        console.log(error);
        setError('Error fetching buttons');
      }
    };
    fetchButtons();
  }, []);

  return (
    <form onSubmit={handleTransaction}>
      <button disabled={isPending || !isPageLoaded} type="submit" className="border_btn">Compliment</button>
      {transactionStatus && <div>{transactionStatus}</div>}
      {isConfirming && <div>Waiting for confirmation...</div>}
      {isConfirmed && <div>Transaction confirmed.</div>}
      {error && <div>{error}</div>}
    </form>
  );
}
