import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';

const AlreadyConnected: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { publicKey } = useWallet();
    const navigate = useNavigate();
    const location = useLocation();
    const getQueryParameter = (name) => {
        return new URLSearchParams(location.search).get(name);
    };
    const extractedClickId = getQueryParameter('xuc');
    useEffect(() => {
        const checkConnection = () => {
            if (publicKey) {
                if(extractedClickId){
                    navigate(`/compliment?xuc=${extractedClickId}`);
                }else{
                    navigate(`/compliment`);
                }
                // Redirect to the desired route
            } else if (location.pathname === '/compliment') {
                navigate('/');
            }
        };
        checkConnection();
    }, [publicKey, navigate, location.pathname]);

    

    return (
        <>
            {children} {/* Render the children components */}
        </>
    );
};

export default AlreadyConnected;
