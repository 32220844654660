import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AlreadyConnected from './AlreadyConnected';
import Result from "./Result";
import Captcha from './Captcha';
import Connect from './Connect';
import Track from './Track';
import Complement from './Complement';

const App = () => {
    const handleFormSubmit = (captchaResponse) => {
        console.log("CAPTCHA Response:", captchaResponse);
    };

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<AlreadyConnected><Captcha onSubmit={handleFormSubmit} /></AlreadyConnected>} />
                    <Route path="/track" element={<Track />} />
                    <Route path="/connect" element={<AlreadyConnected><Connect /></AlreadyConnected>} />
                    <Route path="/compliment" element={<AlreadyConnected><Complement /></AlreadyConnected>} />
                    <Route path="/result/:randomString" element={<Result />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default App;
