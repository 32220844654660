import React, { useState, useEffect } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';
import GetLinks from './GetLinks';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import BackgroundMusic from './BackgroundMusic';
import SharingLink from './SharingLink';

const AutoType = ({ text, delay }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(prev => prev + text[currentIndex]);
        setCurrentIndex(currentIndex + 1);
      }, delay);

      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, text, delay]);

  return <p>{displayedText}</p>;
};

const Result = () => {
  const navigate = useNavigate();
  const { publicKey, disconnect } = useWallet();
  const [response, setResponse] = useState('');
  const delay = 50;
  const { randomString } = useParams();
  console.log(randomString)
  
  useEffect(() => {
    const fetchCompliment = async () => {
      try {
        const result = await axios.get(`https://backend.oh-anon.xyz/getcomplement/${randomString}`, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        
        setResponse(result.data.record);
      } catch (error) {
        console.log(error);
        // navigate('/');
      }
    };
    fetchCompliment();
  }, [navigate]);
  

  const handleDisconnect = async () => {
    await disconnect();
    navigate('/');
  };

  return (
    <div className="page-container">
      <div className='music-box'>
        <BackgroundMusic />
      </div>
      {publicKey && (
        <>
          <button type="button" className='disconnect_btn' onClick={handleDisconnect}>
            Disconnect
          </button>
        </>
      )}
      <div className="content_box">
        <h2>OH-ANON.XYZ</h2>
        <img src="../girl.gif" alt="girl" className="gif-girl" />
        <div className="typed_letter"><AutoType text={response} delay={delay} /></div>
        <GetLinks />
        <SharingLink />
        
      </div>
    </div>
  );
};

export default Result;
