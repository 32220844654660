import React, { useState, useEffect } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import Cookies from 'js-cookie';
import axios from 'axios';
import BackgroundMusic from './BackgroundMusic';
import SharingLink from './SharingLink';

const AutoType = ({ text, delay }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    
    useEffect(() => {
        if (currentIndex < text.length) {
            const timeoutId = setTimeout(() => {
                setDisplayedText(prev => prev + text[currentIndex]);
                setCurrentIndex(currentIndex + 1);
            }, delay);

            return () => clearTimeout(timeoutId);
        }
    }, [currentIndex, text, delay]);

    return <p>{displayedText}</p>;
};

function App() {
    const [isMobile, setIsMobile] = useState(false);
    const account = useConnection();
    const { publicKey } = useWallet();
    const navigate = useNavigate();
    const location = useLocation();
    const getQueryParameter = (name) => {
        return new URLSearchParams(location.search).get(name);
    };
    const extractedClickId = getQueryParameter('xuc');
    //alert(extractedClickId);
    const UserCookie = Cookies.get('UserCookie');
    const sentence = 'OH ANON MY DARLING YOU LOOK LONLEY, I CAN FIX THAT. CONNECT YOUR SOLANA WALLET AND FOR $0.99 SOL I WILL GIVE YOU A COMPLIMENT.';
    const delay = 50;

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        setIsMobile(/iphone|ipod|android|blackberry|opera mini|windows phone|iemobile|mobile/i.test(userAgent));
    }, []);
    
    useEffect(() => {
        const fetchUserRecord = async () => {
            try {
                const response = await axios.get(`https://backend.oh-anon.xyz/users/verifyuniqueid/${UserCookie}`, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                if (response.data.record.id <= 0) {
                    Cookies.remove('UserCookie');
                    alert('Something went wrong, please try againddd');
                    navigate('/');
                }
            } catch (error) {
                alert('Something went wrong, please try againdddfdf');
                navigate('/');
            }
        };
        fetchUserRecord();
    }, [navigate]);
    useEffect(() => {
        if (publicKey) {
            if(extractedClickId){
                navigate(`/compliment?xuc=${extractedClickId}`);
            }else{
                navigate(`/compliment`);
            }
        }
    }, [publicKey,extractedClickId]);
    return (
        <>
            <div>
            <div className='ads-cls' style={{ textAlign: "center"}}>
                {isMobile ? (
                    <a href="https://shop.ledger.com/pages/ledger-nano-s-plus/?r=921bfe5b6b8d">
                        <img width="320" height="50" src="https://affiliate.ledger.com/image/320/50/Default" />
                    </a>
                ) : (
                    <iframe
                    data-aa="2347120"
                    src="//ad.a-ads.com/2347120?size=320x100"
                    style={{
                        width: "320px",
                        height: "100px",
                        border: "0px",
                        padding: "0",
                        overflow: "hidden",
                        backgroundColor: "transparent",
                        textAlign: "center",
                    }}
                    title="Ad Banner 2"
                    ></iframe>
                )}
                </div>
                <div className="page-container">
                    <div className='music-box'>
                        <BackgroundMusic />
                    </div>
                    <div className="content_box">
                        <h2>OH-ANON.XYZ</h2>
                        <img src="/girl.gif" alt="girl" className="gif-girl" />
                        <div className="typed_letter">
                            <AutoType text={sentence} delay={delay} />
                        </div>
                        {publicKey ? (
                            <>
                                <button style={{ marginTop: '15px' }} type="button" className="border_btn" onClick={() => navigate('/compliment')}>
                                    Get Compliment
                                </button>
                            </>
                        ) : (
                            <>
                                <WalletMultiButton className="custom-wallet-button">
                                    Connect Wallet
                                </WalletMultiButton>
                            </>
                        )}
                        <SharingLink />
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
