import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function SharingLink() {
  const navigate = useNavigate();
  const [displayedText, setDisplayedText] = useState({});
const sharetelegram = ''; const sharetwitter = '';

  useEffect(() => {
    const fetchbuttons = async () => {
      try {
        const response = await axios.get('https://backend.oh-anon.xyz/users/getlinks', {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setDisplayedText(response.data);
      } catch (error) {
        console.log(error);
        // navigate('/');
      }
    };
    fetchbuttons();
  }, [navigate]);
  return (
    <div className="share-box">
      {/* <a href={displayedText.complement2} target="_blank" rel="noopener noreferrer">
      <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.6 10.083L4.052 11.594L14 6.167L8 13.167L16.6 17.083C16.7471 17.1474 16.9075 17.1755 17.0677 17.1648C17.2279 17.1541 17.3832 17.105 17.5204 17.0216C17.6577 16.9383 17.7728 16.8231 17.8561 16.6858C17.9394 16.5485 17.9884 16.3932 17.999 16.233L18.999 1.233C19.0101 1.05938 18.9759 0.885859 18.8996 0.729501C18.8233 0.573143 18.7076 0.43934 18.564 0.341259C18.4203 0.243178 18.2535 0.1842 18.0801 0.170127C17.9067 0.156055 17.7326 0.187373 17.575 0.261L0.575 8.261C0.40159 8.34327 0.255458 8.47362 0.153985 8.63655C0.0525116 8.79947 -3.27728e-05 8.9881 0.0026006 9.18003C0.00523397 9.37195 0.0629341 9.55907 0.168839 9.71915C0.274744 9.87922 0.424397 10.0055 0.6 10.083ZM6 19.167L10.776 16.851L6 14.623V19.167Z" fill="black"/>
            </svg>
      </a> */}
      <a href={displayedText.complement1} target="_blank" rel="noopener noreferrer">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 0C0.896875 0 0 0.896875 0 2V12C0 13.1031 0.896875 14 2 14H12C13.1031 14 14 13.1031 14 12V2C14 0.896875 13.1031 0 12 0H2ZM11.2844 2.625L8.04062 6.33125L11.8562 11.375H8.86875L6.53125 8.31563L3.85313 11.375H2.36875L5.8375 7.40938L2.17812 2.625H5.24062L7.35625 5.42188L9.8 2.625H11.2844ZM10.1031 10.4875L4.79375 3.46562H3.90937L9.27812 10.4875H10.1H10.1031Z" fill="black"/>
            </svg>
      </a>
    </div>
  );
}