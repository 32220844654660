import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { useLocation,useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Captcha = ({ onSubmit }) => {
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null);
  Cookies.remove('UserCookie');
  const location = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (captchaValue) {
      const getQueryParameter = (name) => {
        return new URLSearchParams(location.search).get(name);
      };
      const extractedClickId = getQueryParameter('xuc');
      
      try {
        const response = await axios.get(`https://backend.oh-anon.xyz/users/getuniqueid`, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (parseInt(response.data.record) > 0) {
          Cookies.set('UserCookie', response.data.record, { expires: 700 });
          if(extractedClickId){
            navigate(`/connect?xuc=${extractedClickId}`);
          }else{
            navigate('/connect');
          }
        } else {
          navigate('/');
        }
      } catch (responseerror) {
        if (responseerror.response && responseerror.response.data && responseerror.response.data.errors) {
          navigate('/');
        } else {
          navigate('/');
        }
      }
    } else {
      alert("Please complete the CAPTCHA.");
    }
  };

  return (
    <div className="page-container">
      <div className="content_box">
        <form onSubmit={handleSubmit}>
          <ReCAPTCHA
            //live-6Ldu1_MpAAAAABVRr67LoO5pFZuyUu4UEwg-e2d8
            //local-6Le0ms4pAAAAAOhqOqBJJeI4YIMU8GmHavbZJ_HH
            sitekey="6Ldu1_MpAAAAABVRr67LoO5pFZuyUu4UEwg-e2d8" 
            onChange={(value) => setCaptchaValue(value)}
          />
          <button type="submit" className='border_btn captcha-submit' style={{ marginTop: '15px !important' }}>Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Captcha;
