import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const Track = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const trackUser = async () => {
            const getQueryParameter = (name) => {
                return new URLSearchParams(location.search).get(name);
            };

            const extractedClickId = getQueryParameter('sub1');
            if (extractedClickId) {
                const decodedString = btoa(extractedClickId);
                navigate(`/?xuc=${decodedString}`);
            } else {
                navigate('/');
            }
        };

        trackUser();
    }, [location, navigate]);

    return <div></div>;
};

export default Track;
