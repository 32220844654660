import React, { useEffect, useRef, useState } from 'react';

const BackgroundMusic = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayMusic = () => {
    
    if (audioRef.current) {
      audioRef.current.play()
        .then(() => {
          setIsPlaying(true);
          console.log('Music started playing');
        })
        .catch((error) => {
          console.error('Failed to play the audio:', error);
          // Add event listeners for user interaction
          document.addEventListener('click', handlePlayMusic, { once: true });
          document.addEventListener('keydown', handlePlayMusic, { once: true });
          document.addEventListener('mousemove', handlePlayMusic, { once: true });
        });
    }
  };

  const handleStopMusic = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
      console.log('Music stopped');
    }
  };

  useEffect(() => {
    // Remove event listeners when component unmounts
    return () => {
      document.removeEventListener('click', handlePlayMusic);
      document.removeEventListener('keydown', handlePlayMusic);
      document.removeEventListener('mousemove', handlePlayMusic);
    };
  }, []);

  return (
    <div>
      {!isPlaying ? (
        <a href="#" className="play-music-prompt" onClick={(e) => { e.preventDefault(); handlePlayMusic(); }}>
          Play Music
        </a>
      ) : (
        <a href="#" className="stop-music-prompt" onClick={(e) => { e.preventDefault(); handleStopMusic(); }}>
          Play Music
        </a>
      )}
      <audio ref={audioRef} loop>
        <source src="/track.mp3" id="audio" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default BackgroundMusic;
