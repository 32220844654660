// src/contexts/WalletConnectionProvider.js
import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';

require('@solana/wallet-adapter-react-ui/styles.css');

const WalletConnectionContext = createContext();

export const useWalletConnection = () => {
    return useContext(WalletConnectionContext);
};

const WalletConnectionProvider = ({ children }) => {
    const network = WalletAdapterNetwork.Mainnet;

    // Custom RPC URL
    const customRpcUrl = 'https://solana-mainnet.g.alchemy.com/v2/krt1nFnxDG4DYkAom0hAcSoVs5pHy938'; 

    // Fallback to clusterApiUrl if custom RPC URL fails
    const endpoint = useMemo(() => customRpcUrl || clusterApiUrl(network), [network, customRpcUrl]);

    const wallets = useMemo(() => [new PhantomWalletAdapter()], []);

    // Log the endpoint for debugging
    useEffect(() => {
        console.log(`Using RPC endpoint: ${endpoint}`);
    }, [endpoint]);

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <WalletConnectionContext.Provider value={{ network }}>
                        {children}
                    </WalletConnectionContext.Provider>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

export default WalletConnectionProvider;
