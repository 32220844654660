import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '@solana/wallet-adapter-react';
import axios from 'axios';
import { SendTransaction } from './SendTransaction';
import SharingLink from './SharingLink';
import BackgroundMusic from './BackgroundMusic';

const Complement = () => {
    const { publicKey, disconnect } = useWallet();
    const navigate = useNavigate();
    const [displayedText, setDisplayedText] = useState({
        transfer_to: '',
        price1: '',
        complement1: '',
        complement2: '',
        complement3: '',
        complement4: ''
    });
   
    const [isMobile, setIsMobile] = useState(false);
    const handleDisconnect = async () => {
        await disconnect();
        navigate('/');
    };
    

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        setIsMobile(/iphone|ipod|android|blackberry|opera mini|windows phone|iemobile|mobile/i.test(userAgent));
    }, []);
    useEffect(() => {
        const fetchButtons = async () => {
            try {
                const response = await axios.get('https://backend.oh-anon.xyz/users/getlinks', {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                setDisplayedText(response.data);
            } catch (error) {
                console.log(error);
                // navigate('/');
            }
        };
        fetchButtons();
    }, []);
    return (
        <>
        <div className='ads-cls' style={{ textAlign: "center"}}>
            {isMobile ? (
                <a href="https://shop.ledger.com/pages/ledger-nano-s-plus/?r=921bfe5b6b8d">
                    <img width='320' height='50' src="https://affiliate.ledger.com/image/320/50/Default" />
                </a>
            ) : (
                <iframe
                data-aa="2347184"
                src="//ad.a-ads.com/2347184?size=100"
                style={{
                    width: "320px",
                    height: "100px",
                    border: "0px",
                    padding: "0",
                    overflow: "hidden",
                    backgroundColor: "transparent",
                    textAlign: "center",
                }}
                title="Ad Ban 4"
             ></iframe>
                
            )}
        </div>
        <div className="page-container">
            <div className='music-box'>
                <BackgroundMusic />
            </div>
            {publicKey && (
                <>
                    <button type="button" className='disconnect_btn' onClick={handleDisconnect}>
                        Disconnect
                    </button>
                </>
            )}
            <div className="content_box">
                <h2>OH-ANON.XYZ</h2>
                <img src="girl.gif" alt="girl" className="gif-girl" />
                <p>Get a compliment (${displayedText.price1})</p>
                <div className="btn-box">
                    <SendTransaction />
                </div>
                <SharingLink />
            </div>
        </div>
        </>
    );
};

export default Complement;
