// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import WalletContextProvider from './WalletContextProvider';
import '@solana/wallet-adapter-react-ui/styles.css';
import { Buffer } from 'buffer';
window.Buffer = Buffer;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <WalletContextProvider>
      <App />
    </WalletContextProvider>,
  document.getElementById('root')
);


